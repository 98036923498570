import documentManagerAPI from '../../api/documentManger';
import documentMixin from 'shared/mixins/documentMixin';

const initialState = {
  folders: [],
  documents: [],
  selectedFolderDocuments: [],
  show: false,
  isClientVerified: false,
  selectedFolderId: null,
  selectedDocumentId: null,
  isUploading: false,
  isDeleting: false,
  isFetching: false,
  isFolderAdding: false,
};

export const getters = {
  getFolders($state) {
    return $state.folders;
  },
  getShow($state) {
    return $state.show;
  },
  getIsClientVerified($state) {
    return $state.getIsClientVerified;
  },
  getSelectedDocumentId($state) {
    return $state.selectedDocumentId;
  },
  getSelectedFolderId($state) {
    return $state.selectedFolderId;
  },
  getIsUploading($state) {
    return $state.isUploading;
  },
  getIsFolderAdding($state) {
    return $state.isFolderAdding;
  },
  getIsDeleting($state) {
    return $state.isDeleting;
  },
  getDocuments($state) {
    return $state.documents;
  },
  getIsFetching($state) {
    return $state.isFetching;
  },
  getDocumentById($state) {
    return (
      $state.documents.find(doc => doc.id === $state.selectedDocumentId) ?? null
    );
  },
};

export const actions = {
  async getFolders(
    { commit },
    { currentUserId = null, page = 1, limit = 10, accessToken }
  ) {
    documentManagerAPI
      .getFolders(currentUserId, page, limit, accessToken)
      .then(response => {
        const { data: folders } = response.data;
        if (folders.length !== 0) {
          let sortedFolderByCreatedAt = documentMixin.methods.sortObjectsArray(
            folders,
            'createdAt',
            'asc'
          );
          commit('setFolders', sortedFolderByCreatedAt);
          commit('setSelectedFolderId', sortedFolderByCreatedAt[0].id);
        } else {
          documentManagerAPI
            .createFolder('مدارک', currentUserId, accessToken)
            .then(result => {
              const { data: resultData } = result.data;
              commit('createFolder', resultData);
              commit('setSelectedFolderId', resultData.id);
            })
            .catch(error => {
              throw error;
            });
        }
      })
      .catch(error => {
        throw error;
      });
  },

  async createFolder({ commit }, { name, currentUserId, accessToken }) {
    commit('setIsFolderAdding', true);
    documentManagerAPI
      .createFolder(name, currentUserId, accessToken)
      .then(response => {
        const { data } = response.data;
        commit('setSelectedFolderId', data.id);
        commit('createFolder', data);
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        commit('setIsFolderAdding', false);
      });
  },

  async deleteFolder({ commit }, { folderId, accessToken }) {
    documentManagerAPI
      .deleteFolder(folderId, accessToken)
      .then(() => {
        commit('deleteFolder', folderId);
      })
      .catch(error => {
        throw error;
      });
  },

  // async renameFolder({ commit }, newName, folderId) {
  //   documentManagerAPI
  //     .renameFolder(newName, folderId)
  //     .then(response => {
  //       commit('renameFolder', response.data);
  //     })
  //     .catch(error => {
  //       throw error;
  //     });
  // },

  async getDocuments({ commit, state }, { accessToken }) {
    commit('setIsFetching', true);
    const documents = await documentManagerAPI
      .getDocuments(state.selectedFolderId, accessToken)
      .then(response => {
        const { data } = response.data;
        return data;
      })
      .finally(() => {
        commit('setIsFetching', false);
      });
    commit('setDocuments', documents);
  },

  async saveDocuments(
    { commit },
    { currentUserId = null, folderId = null, files = [], accessToken }
  ) {
    documentManagerAPI
      .saveDocument(currentUserId, folderId, files, accessToken)
      .then(response => {
        const { data } = response.data;
        commit('saveDocuments', data);
        commit('updateFolderFilesCount', { folderId, type: 'add' });
      })
      .catch(error => {
        throw error;
      });
  },

  async deleteDocument({ commit }, { docId, selectedFolderId, accessToken }) {
    commit('setIsDeleting', true);
    documentManagerAPI
      .deleteDocument(docId, accessToken)
      .then(() => {
        commit('deleteDocument', docId);
        commit('updateFolderFilesCount', {
          folderId: selectedFolderId,
          type: 'sub',
        });
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        commit('setIsDeleting', false);
      });
  },

  async onUpdateDocument({ commit }, { data, docId, accessToken }) {
    documentManagerAPI
      .updateDocument(data, docId, accessToken)
      .then(() => {
        commit('updateDocument', { data, docId });
      })
      .catch(error => {
        throw error;
      });
  },

  async onCopyDocument({ commit }, { folderId, fileId, accessToken }) {
    documentManagerAPI
      .copyDocument({ folderId, fileId, accessToken })
      .then(() => {
        commit('updateFolderFilesCount', { folderId, type: 'add' });
      });
  },

  async onMoveDocument({ commit, state }, { folderId, fileId, accessToken }) {
    documentManagerAPI
      .moveDocument({ folderId, fileId, accessToken })
      .then(() => {
        commit('updateFolderFilesCount', { folderId, type: 'add' });
        commit('updateFolderFilesCount', {
          folderId: state.selectedFolderId,
          type: 'sub',
        });
        commit('deleteDocument', fileId);
      });
  },

  async onUploadAttachment(
    { commit },
    { attachment = null, account_id = null, conversation_id = null }
  ) {
    commit('setIsUploading', true);
    const result = await documentManagerAPI
      .uploadAttachment({
        attachment,
        account_id,
        conversation_id,
      })
      .then(response => {
        const data = response.data;
        const transformedData = documentMixin.methods.readyAttachmentsForSave(
          data.attachments,
          ''
        );
        return transformedData;
      })
      .finally(() => {
        commit('setIsUploading', false);
      });
    return result;
  },

  setSelectedFolderId({ commit }, folderId) {
    commit('setSelectedFolderId', folderId);
  },

  setSelectedDocumentId({ commit }, docId) {
    commit('setSelectedDocumentId', docId);
  },

  setIsClientVerified({ commit }, isVerify) {
    commit('isClientVerified', isVerify);
  },

  show({ commit }) {
    commit('setShow');
  },

  hide({ commit }) {
    commit('setHide');
  },
};

export const mutations = {
  setFolders(state, data) {
    state.folders = [...data];
  },
  createFolder(state, folder) {
    state.folders.push(folder);
  },
  deleteFolder(state, folderId) {
    state.folders = state.folders.filter(folder => folder.id !== folderId);
    this.setSelectedFolderId = null;
  },
  renameFolder(state, { newName, folderId }) {
    const folder = state.folders.find(f => f.id === folderId);
    if (folder) {
      folder.name = newName;
    }
  },
  saveDocuments(state, files) {
    state.documents = [...state.documents, ...files];
  },
  deleteDocument(state, docId) {
    state.documents = state.documents.filter(doc => doc.id !== docId);
    state.selectedDocumentId = null;
  },
  isClientVerified(state, isClientVerified) {
    state.isClientVerified = isClientVerified;
  },
  setShow(state) {
    state.show = true;
  },
  setHide(state) {
    state.show = false;
    state.selectedDocumentId = null;
    state.isDeleting = false;
    state.isFetching = false;
    state.isFolderAdding = false;
    state.isUploading = false;
  },
  setSelectedFolderId(state, folderId) {
    state.selectedFolderId = folderId;
    state.documents = [];
  },
  setSelectedDocumentId(state, docId) {
    state.selectedDocumentId = docId;
  },
  setIsUploading(state, value) {
    state.isUploading = value;
  },
  setIsDeleting(state, value) {
    state.isDeleting = value;
  },
  setIsFetching(state, value) {
    state.isFetching = value;
  },
  setDocuments(state, documents) {
    state.documents = documents;
  },
  setIsFolderAdding(state, value) {
    state.isFolderAdding = value;
  },
  updateFolderFilesCount(state, { folderId, type }) {
    switch (type) {
      case 'add':
        state.folders = state.folders.map(folder =>
          folder.id === folderId
            ? { ...folder, number_of_files: folder.number_of_files + 1 }
            : folder
        );
        break;
      case 'sub':
        state.folders = state.folders.map(folder =>
          folder.id === folderId
            ? { ...folder, number_of_files: folder.number_of_files - 1 }
            : folder
        );
        break;
      default:
        return null;
    }
    return null;
  },
  updateDocument(state, { data, docId }) {
    state.documents = state.documents.map(doc =>
      doc.id === docId
        ? { ...doc, file_description: data.description, file_name: data.name }
        : doc
    );
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
