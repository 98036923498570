export default {
  methods: {
    getFileType(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      const fileTypes = {
        jpg: { type: 'Image', extension: 'jpg' },
        jpeg: { type: 'Image', extension: 'jpeg' },
        png: { type: 'Image', extension: 'png' },
        webp: { type: 'Image', extension: 'webp' },
        pdf: { type: 'PDF', extension: 'pdf' },
        doc: { type: 'Word Document', extension: 'doc' },
        docx: { type: 'Word Document', extension: 'docx' },
      };
      const fileType = fileTypes[extension] || { type: 'Unknown', extension };
      return fileType;
    },
    humanFileSize(size) {
      const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      return size === 0
        ? '0 Bytes'
        : (size / 1024 ** i).toFixed(2) + ' ' + sizes[i];
    },
    getFileNameAndExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.');
      let name = '';
      let extension = '';

      if (
        lastDotIndex === -1 ||
        lastDotIndex === 0 ||
        lastDotIndex === filename.length - 1
      ) {
        // If no dot is found, or if dot is the first or last character, treat it as no extension
        name = filename;
        extension = '';
      } else {
        // Separate the name and extension
        name = filename.slice(0, lastDotIndex);
        extension = filename.slice(lastDotIndex + 1);
      }

      return { name: decodeURIComponent(name), extension };
    },

    readyAttachmentsForSave(data, description) {
      return (
        data.map(obj => {
          // eslint-disable-next-line no-useless-escape
          const fileNameMatch = obj.data_url.match(/\/([^\/]+)$/);
          const fileName = fileNameMatch ? fileNameMatch[1] : 'unknown';
          return {
            name: decodeURIComponent(fileName),
            path: obj.data_url,
            description: description,
          };
        }) || []
      );
    },
    sortObjectsArray(array, key, order = 'asc') {
      return array.sort((a, b) => {
        if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
        return 0;
      });
    },
  },
};
