import endPoints from './endPoints';
import axios from 'axios';
import createAxios from 'dashboard/helper/APIHelper';

export const API = axios.create({
  baseURL: `${window.chatwootConfig.backendURL}/api/v1/`,
});

export default {
  createFolder(name, userId, accessToken) {
    const payload = { name, userId };
    return API.post(endPoints('documentManager').folders, payload, {
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  deleteFolder(folderId, accessToken) {
    return API.delete(`${endPoints('documentManager').folders}/${folderId}`, {
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  // renameFolder(name, folderId) {
  //   const payload = { name, folderId };
  //   return API.put(endPoints('documentManager').folders, payload);
  // },

  getFolders(userId, page, limit, accessToken) {
    const params = {
      userId,
      page,
      limit,
    };
    return API.get(endPoints('documentManager').folders, {
      params,
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  getDocuments(folderId, accessToken) {
    return API.get(`${endPoints('documentManager').files}/${folderId}`, {
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  saveDocument(userId, folderId, files, accessToken) {
    const payload = { userId, folderId, files };
    return API.post(endPoints('documentManager').files, payload, {
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  deleteDocument(fileId, accessToken) {
    return API.delete(`${endPoints('documentManager').files}/${fileId}`, {
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  async updateDocument(payload, fileId, accessToken) {
    return API.put(`${endPoints('documentManager').files}/${fileId}`, payload, {
      headers: {
        api_access_token: accessToken,
      },
    });
  },

  async copyDocument(payload) {
    const { folderId, fileId, accessToken } = payload;
    return API.put(
      `${endPoints('documentManager').files}/copy/${folderId}`,
      { fileId },
      {
        headers: {
          api_access_token: accessToken,
        },
      }
    );
  },

  async moveDocument(payload) {
    const { folderId, fileId, accessToken } = payload;
    return API.put(
      `${endPoints('documentManager').files}/move/${folderId}`,
      { fileId },
      {
        headers: {
          api_access_token: accessToken,
        },
      }
    );
  },

  async uploadAttachment(payload) {
    const formData = new FormData();
    formData.append('attachments[]', payload.attachment);
    formData.append('private', 'true');
    formData.append('content', '');

    const customAxios = createAxios(axios);

    return customAxios.post(
      `/api/v1/accounts/${payload.account_id}/conversations/${payload.conversation_id}/messages`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};
