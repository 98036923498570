var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[(_vm.show)?_c('div',{class:_vm.modalClassName,attrs:{"transition":"modal"},on:{"mousedown":_vm.handleMouseDown}},[_c('div',{class:{
        'modal-container rtl:text-right shadow-md max-h-full overflow-auto relative bg-white dark:bg-slate-800 skip-context-menu': true,
        'rounded-xl w-[37.5rem]': !_vm.fullWidth,
        'rounded-none h-full w-full': _vm.fullWidth,
        'flex items-center justify-center': _vm.fullWidth && !_vm.noCentering,
        [_vm.size]: true,
      },on:{"mouse":function($event){$event.stopPropagation();},"mousedown":event => event.stopPropagation()}},[_c('div',{staticClass:"flex items-center justify-between pt-3 px-3"},[_c('div',[(_vm.modalTitle)?_c('h4',{staticClass:"font-semibold text-darkBlueGray"},[_vm._v("\n            "+_vm._s(_vm.modalTitle)+"\n          ")]):_vm._e()]),_vm._v(" "),(_vm.showCloseButton)?_c('woot-button',{attrs:{"color-scheme":"secondary","icon":"dismiss","variant":"clear"},on:{"click":_vm.close}}):_vm._e()],1),_vm._v(" "),_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }